.description-container {
    max-height: 50px;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.description-container.collapsed {
    max-height: 50px;
}

.description-container.expanded {
    max-height: 500px; 
}

.truncate-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    max-height: 3em; /* Hauteur maximale pour 2 lignes */
    line-height: 1.5em; /* Hauteur de ligne */
    white-space: normal;
}

.truncate-descriptions {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    max-height: 4.5em; /* Hauteur maximale pour 3 lignes */
    line-height: 1.5em; /* Hauteur de ligne */
    white-space: normal;
}

.produits-container {
    overflow-x: auto;
    white-space: nowrap;
}

.dropdown-suggestions {
    position: absolute;
    background-color: white;
    width: 100%;
    margin-top: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    z-index: 50; /* Contexte d'empilement élevé */
    max-height: 200px; /* Limite la hauteur */
    overflow-y: auto; /* Ajoute un défilement si nécessaire */
}
